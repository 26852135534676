import { Controller } from "@hotwired/stimulus";
import Flickity from "flickity";

export default class extends Controller {
  static targets = [
    "slideWrapper",
    "slide",
    "announcement",
  ];

  static values = {
    index: Number,
    maxIndex: Number,
    slideWidth: Number,
    wrapAround: {
      default: true,
      type: Boolean,
    },
  };

  static buttonTargets = [
    "nextButton",
    "nextButtonLabel",
    "previousButton",
    "previousButtonLabel",
  ];

  static captionTarget = "caption";

  connect() {
    this.initializeSlideWidth();
    this.initializeFlickity();
    this.updateButtonStates();
  }

  disconnect() {
    this.flickity.destroy();
  }

  refresh() {
    this.flickity.resize();
  }

  initializeSlideWidth() {
    // Calculate the width of all slides
    this.slideWidthValue = this.slideTargets.reduce((totalWidth, slide) => {
      return totalWidth + slide.clientWidth;
    }, 0);

    // Disable wrapAround if the slides total width is smaller than the wrapper
    if (this.slideWidthValue < this.slideWrapperTarget.clientWidth) {
      this.wrapAroundValue = false;
    }
  }

  initializeFlickity() {
    this.flickity = new Flickity(this.slideWrapperTarget, {
      cellAlign: "left",
      draggable: true,
      freeScroll: false,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: this.wrapAroundValue,
    });

    this.flickity.on("select", this.updateIndex.bind(this));
  }

  updateIndex(index) {
    this.indexValue = index;
  }

  indexValueChanged(index, previousIndex) {
    // Swap out captions
    if (this.hasCaptionTarget) {
      const currentCaption = this.captionTarget.firstElementChild;
      const fadeOutCaption = currentCaption
        ? currentCaption
          .animate([{ opacity: 0 }], {
            duration: 200,
            easing: "ease-in",
            fill: "forwards",
          })
          .finished.then(() => {
            return currentCaption.remove();
          })
        : Promise.resolve();
      fadeOutCaption.then(() => {
        const currentSlide = this.slideTargets[index];
        const currentCaptionTemplate = currentSlide.querySelector(
          ".js-carousel-caption"
        );
        const fragment = currentCaptionTemplate.content.cloneNode(true);
        this.captionTarget.appendChild(fragment);
        const currentCaption = this.captionTarget.firstElementChild;
        currentCaption.animate([{ opacity: 0 }, { opacity: 1 }], {
          duration: 200,
          easing: "ease-out",
          fill: "forwards",
        });
      });
    }

    if (this.hasNextButtonTarget) {
      // Update button states
      if (this.nextIndex === false) {
        this.nextButtonTarget.setAttribute("disabled", "");
      } else {
        this.nextButtonTarget.removeAttribute("disabled");
      }
    }

    if (this.hasPreviousButtonTarget) {
      if (this.previousIndex === false) {
        this.previousButtonTarget.setAttribute("disabled", "");
      } else {
        this.previousButtonTarget.removeAttribute("disabled");
      }
    }

    if (this.hasNextButtonLabelTarget) {
      this.nextButtonLabelTarget.textContent = `Go to slide #${
        this.nextIndex + 1
      }`;
    }

    if (this.hasPreviousButtonLabelTarget) {
      this.previousButtonLabelTarget.textContent = `Go to slide #${
        this.previousIndex + 1
      }`;
    }

    if (this.hasAnnouncementTarget) {
      this.announcementTarget.textContent = `You are currently viewing slide ${
        index + 1
      } of ${this.slideTargets.length}`;
    }
  }

  updateButtonStates() {
    const isNextDisabled = this.nextIndex === false;
    const isPreviousDisabled = this.previousIndex === false;

    if (this.hasNextButtonTarget) {
      this.nextButtonTarget.disabled = isNextDisabled;
      if (this.hasNextButtonLabelTarget) {
        this.nextButtonLabelTarget.textContent = isNextDisabled ?
          "No more slides" : `Go to slide #${this.nextIndex + 1}`;
      }
    }

    if (this.hasPreviousButtonTarget) {
      this.previousButtonTarget.disabled = isPreviousDisabled;
      if (this.hasPreviousButtonLabelTarget) {
        this.previousButtonLabelTarget.textContent = isPreviousDisabled ?
          "No more slides" : `Go to slide #${this.previousIndex + 1}`;
      }
    }

    if (this.hasAnnouncementTarget) {
      this.announcementTarget.textContent = `You are currently viewing slide ${this.indexValue + 1} of ${this.slideTargets.length}`;
    }
  }

  next() {
    if (this.nextIndex !== false) {
      this.flickity.next();
    }
  }

  previous() {
    if (this.previousIndex !== false) {
      this.flickity.previous();
    }
  }

  get nextIndex() {
    if (this.wrapAroundValue) {
      if (this.indexValue === this.maxIndexValue) {
        return 0;
      }
      return this.indexValue + 1;
    }

    if (this.indexValue < this.maxIndexValue) {
      return this.indexValue + 1;
    }

    return false;
  }

  get previousIndex() {
    if (!this.wrapAroundValue) {
      if (this.indexValue === 0 || this.maxIndexValue === 0) {
        return false;
      }
      return this.indexValue - 1;
    }

    if (this.indexValue === 0) {
      return this.maxIndexValue;
    }

    return this.indexValue - 1;
  }
}
