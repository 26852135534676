import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "body",
    "toggler",
  ];

  static values = {
    open: Boolean,
    hasRefreshedCarousel: {
      type: Boolean,
      default: true,
    },
  };

  static outlets = [
    "carousel",
  ];

  connect() {
    if (this.hasCarouselOutlet) {
      if (!this.openValue) {
        this.hasRefreshedCarouselValue = false;
      }
    }
  }

  openValueChanged(open) {
    if (open) {
      this.element.classList.remove("accordion--closed");
      this.element.classList.add("accordion--open");
      this.togglerTarget.setAttribute("checked", "true");

      if (!this.hasRefreshedCarouselValue) {
        this.refreshCarousel();
      }
    }
    else {
      this.element.classList.remove("accordion--open");
      this.element.classList.add("accordion--closed");
      this.togglerTarget.removeAttribute("checked");
    }
  }

  refreshCarousel() {
    this.carouselOutlets.forEach((carousel) => {
      if (this.element.contains(carousel.element)) {
        carousel.refresh();
        this.hasRefreshedCarouselValue = true;
      }
    });
  }

  toggle() {
    this.openValue = !this.openValue;
  }
}
