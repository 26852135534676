import app from "../a4_app";

export class DatabaseEmbedModule {
  constructor(element) {
    this.element = element;
    this.connect();
  }

  connect() {
    if (document.body.classList.contains("action--show")) {
      this.element.addEventListener("turbo:frame-render", (event) => {
        const frame = event.target;
        const resultCountElement = frame.querySelector("[data-result-count]");
        const resultCount = resultCountElement ? parseInt(resultCountElement.getAttribute("data-result-count")) : NaN;

        if (resultCount === 0) {
          this.element.classList.add("d-none");
        }
      })
    }
  }
}

export const databaseEmbedModules = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(".js-database-embed-module");

      if (!Boolean(elements.length)) return;

      const instances = [...elements].map(
        (element) => new DatabaseEmbedModule(element)
      );

      databaseEmbedModules.current = [...databaseEmbedModules.current, ...instances];
    });
  }
}
