import app from "../a4_app";

export class HomepageHeaderModule {
  constructor(element) {
    this.element = element;
    this.height = this.element.clientHeight;
    this.connect();
  }

  connect() {
    this.unlistenScroll = app.addEventListener("scroll", (e) => {
      this.handleScroll();
    });

    this.unlistenResize = app.addEventListener("resize", (e) => {
      this.height = this.element.clientHeight;
    });

    this.unlistenBeforeCache = app.addEventListener("turbo:before-cache", () => {
      this.disconnect();
    });
  }

  disconnect() {
    this.removeScrolledState();
    this.unlistenScroll();
    this.unlistenResize();
    this.unlistenBeforeCache();
  }

  addScrolledState() {
    document.body.classList.add("homepage-header-module--scrolled");
  }

  removeScrolledState() {
    document.body.classList.remove("homepage-header-module--scrolled");
  }

  handleScroll() {
    if (window.scrollY > this.height) {
      this.addScrolledState();
    }
    else {
      this.removeScrolledState();
    }
  }
}

export const homepageHeaderModules = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(".js-homepage-header-module");

      if (!Boolean(elements.length)) return;

      const instances = [...elements].map(
        (element) => new HomepageHeaderModule(element)
      );

      homepageHeaderModules.current = [...homepageHeaderModules.current, ...instances];
    });
  }
}
