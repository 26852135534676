import { Controller } from "@hotwired/stimulus";
import { clamp } from "../utilities";

const MIN_DREAM_STATE_SIZE = 40; // px
export default class extends Controller {
  static targets = [
    "stage",
    "image",
    "boundingBox",
    "handle",
    "blindersContainer",
    "blinder",
    "coordinatesInput",
  ];
  static values = {
    stageDimensions: Object,
    coordinates: Object,
    drawingPoints: Object,
    scalingPoints: Object,
    scalingDimensions: Object,
    touched: Boolean,
    isDrawing: Boolean,
    isScaling: Boolean,
    isMoving: Boolean,
  };

  connect() {
    this.setStageDimensions();
    window.dreamStates ||= [];
    window.dreamStates.push(this);
  }

  updateCoordinates(update) {
    const nextValue = Object.assign({}, this.coordinatesValue, update);
    nextValue.width = clamp(nextValue.width, 0, 1);
    nextValue.height = clamp(nextValue.height, 0, 1);
    nextValue.top = clamp(nextValue.top, 0, 1 - nextValue.height);
    nextValue.left = clamp(nextValue.left, 0, 1 - nextValue.width);

    // Derived values. Do not pass these in.
    nextValue.bottom = nextValue.top + nextValue.height;
    nextValue.right = nextValue.left + nextValue.width;
    nextValue.left_px = nextValue.left * this.stageDimensionsValue.width;
    nextValue.top_px = nextValue.top * this.stageDimensionsValue.height;
    nextValue.right_px = nextValue.right * this.stageDimensionsValue.width;
    nextValue.bottom_px = nextValue.bottom * this.stageDimensionsValue.height;
    nextValue.width_px = nextValue.width * this.stageDimensionsValue.width;
    nextValue.height_px = nextValue.height * this.stageDimensionsValue.height;

    this.coordinatesValue = nextValue;
  }

  updateScalingDimensions(update) {
    const nextValue = Object.assign({}, this.scalingDimensionsValue, update);
    nextValue.width = clamp(nextValue.width, 0, 1);
    nextValue.height = clamp(nextValue.height, 0, 1);
    nextValue.top = clamp(nextValue.top, 0, 1 - nextValue.height);
    nextValue.left = clamp(nextValue.left, 0, 1 - nextValue.width);
    nextValue.bottom = nextValue.top + nextValue.height;
    nextValue.right = nextValue.left + nextValue.width;
    this.scalingDimensionsValue = nextValue;
  }

  setStageDimensions() {
    const boundingBox = this.imageTarget.getBoundingClientRect();
    this.stageDimensionsValue = {
      width: boundingBox.width,
      height: boundingBox.height,
      top: boundingBox.top,
      left: boundingBox.left,
      minDreamStateWidth: MIN_DREAM_STATE_SIZE / boundingBox.width,
      minDreamStateHeight: MIN_DREAM_STATE_SIZE / boundingBox.height,
    };
  }

  updateBoundingBox(coordinates) {
    const { width, height, top, left } = coordinates;
    const boundingBox = this.boundingBoxTarget;
    boundingBox.style.width = `${width * 100}%`;
    boundingBox.style.height = `${height * 100}%`;
    boundingBox.style.top = `${top * 100}%`;
    boundingBox.style.left = `${left * 100}%`;

    // Top
    this.blinderTargets[0].style.width = "100%";
    this.blinderTargets[0].style.height = `${top * 100}%`;
    this.blinderTargets[0].style.top = "0";

    // Right
    this.blinderTargets[1].style.width = `100%`;
    this.blinderTargets[1].style.height = "100%";
    this.blinderTargets[1].style.top = "0";
    this.blinderTargets[1].style.left = `${(left + width) * 100}%`;

    // Bottom
    this.blinderTargets[2].style.width = "100%";
    this.blinderTargets[2].style.height = `100%`;
    this.blinderTargets[2].style.top = `${(top + height) * 100}%`;
    this.blinderTargets[2].style.left = "0";

    // Left
    this.blinderTargets[3].style.width = `${left * 100}%`;
    this.blinderTargets[3].style.height = "100%";
    this.blinderTargets[3].style.top = "0";
    this.blinderTargets[3].style.left = "0";
  }

  /*
   ******************
   * Event Handlers *
   ******************
   */

  getMousePosition(event) {
    const left_px = event.clientX - this.stageDimensionsValue.left;
    const top_px = event.clientY - this.stageDimensionsValue.top;
    let left = left_px / this.stageDimensionsValue.width;
    let top = top_px / this.stageDimensionsValue.height;
    left = clamp(left, 0, 1);
    top = clamp(top, 0, 1);
    return { left_px, top_px, left, top };
  }

  handleMousedown(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.setStageDimensions();
    const mousePosition = this.getMousePosition(event);

    if (event.target.closest(".js-dream-state-editor__handle")) {
      return;
    }

    // If there are coordinates already saved and if the mousedown occurred within the bounding box of those coordinates, then we're moving the bounding box.
    if (
      this.coordinatesValue &&
      mousePosition.left >= this.coordinatesValue.left &&
      mousePosition.left <=
        this.coordinatesValue.left + this.coordinatesValue.width &&
      mousePosition.top >= this.coordinatesValue.top &&
      mousePosition.top <=
        this.coordinatesValue.top + this.coordinatesValue.height
    ) {
      this.startMoving(mousePosition);
    } else {
      this.startDrawing(mousePosition);
    }
  }

  handleMousemove(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    const mousePosition = this.getMousePosition(event);

    if (this.isDrawingValue) {
      this.draw(mousePosition);
    } else if (this.isScalingValue) {
      this.scale(mousePosition);
    } else if (this.isMovingValue) {
      this.move(mousePosition);
    }
  }

  handleMouseup(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    clearTimeout(this.drawingDelay);

    if (this.isDrawingValue) {
      this.stopDrawing();
    } else if (this.isScalingValue) {
      this.stopScaling();
    } else if (this.isMovingValue) {
      this.stopMoving();
    } else if (this.stageTarget.contains(event.target)) {
      this.reset();
    }
  }

  startDrawing(mousePosition) {
    clearTimeout(this.drawingDelay);

    this.drawingPointsValue = mousePosition;

    this.drawingDelay = setTimeout(() => {
      this.isDrawingValue = true;
      this.touchedValue = true;
    }, 250);
  }

  draw(mousemovePosition) {
    if (!this.isDrawingValue) {
      return;
    }

    const update = {};
    const mousedownPosition = this.drawingPointsValue;
    const deltaX = Math.abs(
      mousedownPosition.left_px - mousemovePosition.left_px
    );
    const deltaY = Math.abs(
      mousedownPosition.top_px - mousemovePosition.top_px
    );

    // The drawn bounding box must always be a square. If the user has dragged the mouse further horizontally than vertically, then we need to adjust the vertical dimension of the bounding box to match the horizontal dimension.
    if (deltaX > deltaY) {
      update.width = deltaX / this.stageDimensionsValue.width;
      update.height = deltaX / this.stageDimensionsValue.height;
    } else {
      update.width = deltaY / this.stageDimensionsValue.width;
      update.height = deltaY / this.stageDimensionsValue.height;
    }

    // In order to minimize drift, the top/left position of the bounding box is always defined relative to the mousedown position, where the user first started the "draw" action.
    if (
      mousemovePosition.left > mousedownPosition.left &&
      mousemovePosition.top > mousedownPosition.top
    ) {
      // Rectangle is drawn from top left to bottom right.
      update.top = mousedownPosition.top;
      update.left = mousedownPosition.left;
    } else if (
      mousemovePosition.left < mousedownPosition.left &&
      mousemovePosition.top > mousedownPosition.top
    ) {
      // Rectangle is drawn from top right to bottom left.
      update.top = mousedownPosition.top;
      update.left = mousedownPosition.left - update.width;
    } else if (
      mousemovePosition.left < mousedownPosition.left &&
      mousemovePosition.top < mousedownPosition.top
    ) {
      // Rectangle is drawn from bottom right to top left.
      update.top = mousedownPosition.top - update.height;
      update.left = mousedownPosition.left - update.width;
    } else if (
      mousemovePosition.left > mousedownPosition.left &&
      mousemovePosition.top < mousedownPosition.top
    ) {
      // Rectangle is drawn from bottom left to top right.
      update.top = mousedownPosition.top - update.height;
      update.left = mousedownPosition.left;
    }

    this.updateCoordinates(update);
  }

  stopDrawing() {
    clearTimeout(this.drawingDelay);
    this.isDrawingValue = false;

    // When the user stops drawing, we need to make sure that the
    // dream state isn't too small, so we enforce a minimum size.
    // We don't do this while the user is drawing because it causes
    // a weird UX to have the click-and-drag pattern begin with a
    // square that might not be in the right position.
    const update = {};
    if (
      this.coordinatesValue.width < this.stageDimensionsValue.minDreamStateWidth
    ) {
      update.width = this.stageDimensionsValue.minDreamStateWidth;
    }
    if (
      this.coordinatesValue.height <
      this.stageDimensionsValue.minDreamStateHeight
    ) {
      update.height = this.stageDimensionsValue.minDreamStateHeight;
    }
    if (update.width || update.height) {
      this.updateCoordinates(update);
    }
  }

  startScaling(event) {
    if (!this.touchedValue) {
      return;
    }

    this.isScalingValue = true;
    this.scalingDimensionsValue = this.coordinatesValue;
    this.currentHandle = event.params.handle;
    switch (this.currentHandle) {
      case "top-left":
        // If top left is clicked, we scale relative to the bottom right.
        this.scalingPointsValue = {
          top: this.coordinatesValue.bottom,
          left: this.coordinatesValue.right,
          top_px: this.coordinatesValue.bottom_px,
          left_px: this.coordinatesValue.right_px,
        };
        break;
      case "bottom-left":
        // If bottom left is clicked, we scale relative to the top right.
        this.scalingPointsValue = {
          top: this.coordinatesValue.top,
          left: this.coordinatesValue.right,
          top_px: this.coordinatesValue.top_px,
          left_px: this.coordinatesValue.right_px,
        };
        break;
      case "top-right":
        // If top right is clicked, we scale relative to the bottom left.
        this.scalingPointsValue = {
          top: this.coordinatesValue.bottom,
          left: this.coordinatesValue.left,
          top_px: this.coordinatesValue.bottom_px,
          left_px: this.coordinatesValue.left_px,
        };
        break;
      case "bottom-right":
        // If bottom right is clicked, we scale relative to the top left.
        this.scalingPointsValue = {
          top: this.coordinatesValue.top,
          left: this.coordinatesValue.left,
          top_px: this.coordinatesValue.top_px,
          left_px: this.coordinatesValue.left_px,
        };
        break;
    }
  }

  scale(mousemovePosition) {
    if (
      !this.touchedValue ||
      !this.isScalingValue ||
      mousemovePosition.top_px < 0 ||
      mousemovePosition.left_px < 0 ||
      mousemovePosition.top_px > this.stageDimensionsValue.height ||
      mousemovePosition.left_px > this.stageDimensionsValue.width
    ) {
      return;
    }

    const update = {};
    const mousedownPosition = this.scalingPointsValue;
    const deltaX = Math.abs(
      mousedownPosition.left_px - mousemovePosition.left_px
    );
    const deltaY = Math.abs(
      mousedownPosition.top_px - mousemovePosition.top_px
    );

    // The drawn bounding box must always be a square. If the user has dragged the mouse further horizontally than vertically, then we need to adjust the vertical dimension of the bounding box to match the horizontal dimension.
    if (deltaX > deltaY) {
      update.width = deltaX / this.stageDimensionsValue.width;
      update.height = deltaX / this.stageDimensionsValue.height;
    } else {
      update.width = deltaY / this.stageDimensionsValue.width;
      update.height = deltaY / this.stageDimensionsValue.height;
    }

    // In order to minimize drift, the top/left position of the bounding box is always defined relative to the mousedown position, where the user first started the "draw" action.
    if (
      mousemovePosition.left > mousedownPosition.left &&
      mousemovePosition.top > mousedownPosition.top
    ) {
      // Rectangle is drawn from top left to bottom right.
      update.top = mousedownPosition.top;
      update.left = mousedownPosition.left;
    } else if (
      mousemovePosition.left < mousedownPosition.left &&
      mousemovePosition.top > mousedownPosition.top
    ) {
      // Rectangle is drawn from top right to bottom left.
      update.top = mousedownPosition.top;
      update.left = mousedownPosition.left - update.width;
    } else if (
      mousemovePosition.left < mousedownPosition.left &&
      mousemovePosition.top < mousedownPosition.top
    ) {
      // Rectangle is drawn from bottom right to top left.
      update.top = mousedownPosition.top - update.height;
      update.left = mousedownPosition.left - update.width;
    } else if (
      mousemovePosition.left > mousedownPosition.left &&
      mousemovePosition.top < mousedownPosition.top
    ) {
      // Rectangle is drawn from bottom left to top right.
      update.top = mousedownPosition.top - update.height;
      update.left = mousedownPosition.left;
    }

    if (
      update.top < 0 ||
      update.left < 0 ||
      update.top > 1 ||
      update.left > 1
    ) {
      return;
    }

    // Make sure the box doesn't get too small
    if (update.width < this.stageDimensionsValue.minDreamStateWidth) {
      update.width = this.stageDimensionsValue.minDreamStateWidth;
    }
    if (update.height < this.stageDimensionsValue.minDreamStateHeight) {
      update.height = this.stageDimensionsValue.minDreamStateHeight;
    }

    this.updateScalingDimensions(update);
  }

  stopScaling() {
    if (!this.touchedValue || !this.isScalingValue) {
      return;
    }

    this.isScalingValue = false;
    this.currentHandle = null;
    const finalDimensions = this.scalingDimensionsValue;
    this.scalingDimensionsValue = {};
    this.updateCoordinates(finalDimensions);
  }

  startMoving({ left, top }) {
    if (!this.touchedValue) {
      return;
    }

    this.isMovingValue = true;
    this.movingPointsValue = { left, top };
  }

  move({ left, top }) {
    if (!this.touchedValue || !this.isMovingValue) {
      return;
    }

    const deltaX = this.movingPointsValue.left - left;
    const deltaY = this.movingPointsValue.top - top;
    const update = {};
    update.left = this.coordinatesValue.left - deltaX;
    update.top = this.coordinatesValue.top - deltaY;
    this.updateCoordinates(update);

    this.movingPointsValue = { left, top };
  }

  stopMoving() {
    this.isMovingValue = false;
  }

  reset() {
    this.coordinatesValue = {};
    this.drawingPointsValue = {};
    this.movingPointsValue = {};
    this.touchedValue = false;
    this.isDrawingValue = false;
    this.isMovingValue = false;
    this.isScalingValue = false;
  }

  /*
   ******************
   * Value Handlers *
   ******************
   */

  touchedValueChanged(touched) {
    if (touched) {
      this.boundingBoxTarget.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 250,
        easing: "ease-in-out",
        fill: "forwards",
      });
      this.blindersContainerTarget.animate([{ opacity: 0 }, { opacity: 0.5 }], {
        duration: 250,
        easing: "ease-in-out",
        fill: "forwards",
      });
    } else {
      this.boundingBoxTarget.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: 250,
        easing: "ease-in-out",
        fill: "forwards",
      });

      this.blindersContainerTarget.animate([{ opacity: 0.5 }, { opacity: 0 }], {
        duration: 250,
        easing: "ease-in-out",
        fill: "forwards",
      });
    }
  }

  scalingDimensionsValueChanged(dimensions) {
    this.updateBoundingBox(dimensions);
  }

  coordinatesValueChanged(coordinates) {
    this.updateBoundingBox(coordinates);
    this.coordinatesInputTarget.value = JSON.stringify(coordinates);
  }
}
