import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highlight"
export default class extends Controller {
  static targets = ["button"]

  connect() {
  }

  activate() {
    // If any buttonTargets have the class "active", remove it
    this.buttonTargets.forEach((button) => {
      button.classList.remove("active");
    });
    // Add the class "active" to the clicked buttonTarget
    event.currentTarget.classList.add("active");
  }
}
