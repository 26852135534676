import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-submitter"
export default class extends Controller {
  static targets = [ "form", "checkbox", "column", "clearButton", "input", "noneIncluded" ]

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').content    
  }

  submit(event) {
    this.setNoneIncluded();
    this.formTarget.requestSubmit();
  }

  submitColumn(event) {
    this.columnTargets.forEach((column) => {
      if (column.contains(event.target)) {
        const checkboxes = column.querySelectorAll("input[type='checkbox']")
        checkboxes.forEach((checkbox) => {
          checkbox.checked = true;
        })
      }
    })

    this.submit();
  }

  clearColumn(event) {
    this.columnTargets.forEach((column) => {
      if (column.contains(event.target)) {
        const checkboxes = column.querySelectorAll("input[type='checkbox']")
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        })
      }
    })

    this.submit();
  }

  // Clear all checkboxTargets except the one that is a sibling to the event target, then submit the form.
  submitOnly(event) {
    const checkboxSibling = event.target.parentElement.querySelector("input[type='checkbox']");
    this.checkboxTargets.forEach((checkboxTarget) => {
      if (checkboxTarget != checkboxSibling) {
        checkboxTarget.checked = false;
      } else {
        checkboxTarget.checked = true;
      }
    })

    this.submit();
  }

  submitClearedInput(event) {
    // get the input whose parent contains the event target
    const input = this.inputTargets.find(input => input.parentElement.contains(event.target));
    input.value = "";

    // do the same for the hidden input
    const hiddenInput = this.formTarget.querySelector(`input[name="${event.params.formParam}"]`);
    if (hiddenInput) { hiddenInput.remove() }
    this.submit();
  }

  // If all of the checkbox targets have checked = false, set the hidden noneIncluded input to true.
  setNoneIncluded() {
    if (this.checkboxTargets.every(checkbox => checkbox.checked == false)) {
      this.noneIncludedTarget.checked = true;
    } else {
      this.noneIncludedTarget.checked = false;
    }
  }

  // Gets the current FormData, appends it to the base URL as a query string, removes the authenticity_token parameter, copies the URL to the clipboard, and alerts the user.
  getFormURL() {
    const url = new URL(this.formTarget.action)
    const data = new FormData(this.formTarget)

    data.delete("authenticity_token")
    url.search = new URLSearchParams(data).toString()

    navigator.clipboard
      .writeText(url.pathname.toString() + url.search.toString())
      .then(() => {
        alert("Copied filter URL to clipboard:\n\n" + url.pathname.toString() + url.search.toString());
      })
      .catch(() => {
        alert("something went wrong");
      });
  }
}
