import app from "../a4_app";
import focusLock from "dom-focus-lock";

class Modal {
  constructor(element) {
    this.element = element;
    this.closeBtn = this.element.querySelector(".js-modal-close");
    this.title = this.element.querySelector(".js-modal-title");
    this.imageContainer = this.element.querySelector(".js-modal-image-container");
    this.text = this.element.querySelector(".js-modal-text");
    this.link = this.element.querySelector(".js-modal-link");

    this.closeBtn.addEventListener("click", () => {
      this.close();
    });

    this.link.addEventListener("click", () => {
      this.disconnect();
    });
  }

  disconnect() {
    document.documentElement.style.setProperty("overflow", "auto");
    focusLock.off(this.element);
  }

  fill(obj) {
    if (obj.title) {
      this.title.innerText = obj.title
    };

    if (obj.image.url) {
      const image = document.createElement("img");
      image.setAttribute("src", obj.image.url);
      image.setAttribute("alt", obj.image.alt);
      this.imageContainer.innerHTML = "";
      this.imageContainer.appendChild(image);
      this.imageContainer.classList.remove("d-none");
    }

    if (obj.text) {
      this.text.innerHTML = obj.text;
    }

    this.link.setAttribute("href", obj.link.url);
    this.link.innerText = obj.link.text;
  }

  clear() {
    this.title.innerText = "A4";
    this.imageContainer.classList.add("d-none");
    this.text.innerText = "";
    this.link.setAttribute("href", "");
    this.link.innerText = "";
  }

  open() {
    this.element.classList.remove("d-none");
    document.documentElement.style.overflow = "hidden";
    focusLock.on(this.element);
  }


  close() {
    this.element.classList.add("d-none");
    this.clear();
    this.disconnect();
  }
}

export const modal = {
  current: "",
  init: () => {
    app.addEventListener("page-load", (e) => {
      const element = e.target.querySelector(".js-modal");
      if (!Boolean(element)) return;
      modal.current = new Modal(element);
    });
  }
}
