class App {
  constructor() {
    this.rootFontSize = App.getRootFontSize();

    // Breakpoints in rem units
    // Matches breakpoints in breakpoint.scss
    // NOTE: this must be from largest to smallest
    this.breakpoints = {
      "xxl": 112.5, // 1800px+
      "xl": 80,     // 1280px - 1799px
      "lg": 64,     // 1024px – 1279px
      "md": 46.5,   // 744px – 1023px
      "sm": 37.5,   // 600px – 743px
      "xs": 0,      // 0px – 599px
    };

    // Initial state
    this.state = {
      breakpoint: this.getBreakpoint(),
    };

    // Out-of-the-box events
    // NOTE: turbo:* events won't fire unless Turbo is enabled
    const builtInEvents = [
      { parent: document, type: "turbo:load" },
      { parent: document, type: "turbo:visit" },
      { parent: document, type: "turbo:before-visit" },
      { parent: document, type: "turbo:before-render" },
      { parent: document, type: "turbo:before-cache" },
      { parent: document, type: "DOMContentLoaded" },
      { parent: document, type: "focusin" },
      { parent: document, type: "focusout" },
      { parent: document, type: "mouseenter" },
      { parent: document, type: "mouseleave" },
      { parent: document, type: "click" },
      { parent: document, type: "change" },
      { parent: document, type: "keydown" },
      { parent: document, type: "keyup" },
      { parent: window, type: "scroll", throttle: 100 },
      { parent: window, type: "resize", debounce: 200 },
      { parent: window, type: "breakpoint" },
      { parent: document, type: "page-load" },
      { parent: window, type: "popstate" },
      { parent: document, type: "economy:magic_module_updated" },
      { parent: document, type: "economy:magic_module_order_updated" },
      { parent: document, type: "economy:magic_module_edit" },
    ];

    // Build standardized events object
    this.events = []

    // Attach a listener for each registered event type,
    // optionally debouncing the handlers.
    builtInEvents.forEach((eventConfig) => {
      let executeAllHandlers = (originalEvent) => {
        this.emit(eventConfig.type, originalEvent);
      };

      eventConfig.parent.addEventListener(eventConfig.type, executeAllHandlers);
    });

    // Setup custom events
    this.addEventListener("resize", {
      name: "breakpoint-checker",
      handler: () => {
        const previousBreakpoint = this.state.breakpoint;
        const currentBreakpoint = this.getBreakpoint();
        if (currentBreakpoint !== this.state.breakpoint) {
          this.state.breakpoint = currentBreakpoint;
          this.emit("breakpoint", {
            previous: previousBreakpoint,
            current: currentBreakpoint,
          });
        }
      },
    });

    this.addEventListener("turbo:load", () => {
      this.emit("page-load", {
        target: document.body,
        originalEvent: "turbo:load"
      });
    });

    $(window).on("economy:magic_module_updated", (e) => {
      this.emit("page-load", {
        target: e.target,
        originalEvent: "economy:magic_module_updated"
      });

      this.emit("economy:magic_module_updated", {
        target: e.target,
      });
    });

    $(window).on("economy:magic_module_order_updated", () => {
      const container = document.getElementById("magic-modules");
      this.emit("economy:magic_module_order_updated", {
        target: container,
        magicModules: container.querySelectorAll(".mm"),
      });
    });

    $(window).on("economy:init:fields", (e) => {
      this.emit("economy:magic_module_edit", {
        target: e.target,
      });
    });

    this.addEventListener('main-nav:load', (e) => {
      this.emit("page-load", e);
    })
  }

  addEventListener(type, e) {
    if (!this.events[type]) {
      this.events[type] = {};
    }

    const name = e.name || Object.keys(this.events[type]).length;
    const handler = e instanceof Function ? e : e.handler;
    this.events[type][name] = handler;

    return this.removeEventListener.bind(this, type, name);
  }

  removeEventListener(type, data) {
    const name = typeof data === "object" ? data.name : data;
    if (this.events[type] && this.events[type][name]) {
      delete this.events[type][name];
    }
  }

  emit(type, originalEvent) {
    if (!this.events[type]) {
      return;
    }

    Object.values(this.events[type]).forEach((handler) => {
      if (handler instanceof Function) {
        handler(originalEvent);
      }
    });
  }

  updateState(update) {
    this.state = Object.assign({}, this.state, update);
  }

  // NOTE: do not call this method directly. Opt instead to use `this.state.breakpoint`
  getBreakpoint() {
    const viewportWidth = window.innerWidth / this.rootFontSize;
    const [breakpoint] = Object.entries(this.breakpoints).find(
      ([_, minViewportSize]) => viewportWidth >= minViewportSize // eslint-disable-line no-unused-vars
    );
    return breakpoint;
  }

  isMobile() {
    return this.state.breakpoint === "xs";
  }

  // NOTE: css media queries use rem units, so our js recreation of breakpoint should too.
  // We need the root font size in pixels to convert `window.innerWidth` to rem units.
  // `getComputedStyle` causes DOM reflow, though, so we want to minimize how often we check it.
  static getRootFontSize() {
    return parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
}

export default new App();
