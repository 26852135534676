import app from "../a4_app";
import { modal } from "../components/modal";

export class DetailsModule {
  constructor(element) {
    this.menuItems = element.querySelectorAll(".js-details-module-menu-item");
    this.image = element.querySelector(".js-details-module-image");
    this.text = element.querySelector(".js-details-module-text");
    this.link = element.querySelector(".js-details-module-link");

    this.init();
  }

  toggleMenuItem(item) {
    this.menuItems.forEach((i) => {
      if (i !== item) i.classList.remove("active");
      item.classList.add("active");
    });
  }

  init() {
    this.menuItems.forEach((item) => {
      if (item.dataset.imageUrl) {
        const preloadImage = new Image();
        preloadImage.src = item.dataset.imageUrl;
      }

      item.addEventListener("click", (e) => {
        const data = e.target.dataset;
        this.toggleMenuItem(item);

        if (app.isMobile()) {
          modal.current.fill({
            title: data.title,
            image: {
              url: data.imageUrl,
              alt: data.imageUrlAlt
            },
            text: data.text,
            link: {
              url: data.linkUrl,
              text: data.linkText
            }
          });
          modal.current.open();
        }
        else {
          if (this.image && data.imageUrl) {
            // Some imageUrls are being autoset as blank SVGs, which
            // doesn't work with the module's image element
            if (data.imageUrl.startsWith("https://")) {
              this.image.setAttribute("src", data.imageUrl);
              this.image.classList.remove("d-none");
              this.image.setAttribute("alt", data.imageUrlAlt);
            }
            else {
              this.image.classList.add("d-none");
            }
          }
          else if (this.image) {
            this.image.classList.add("d-none");
          }

          if (data.text) {
            this.text.innerHTML = data.text;
            this.text.classList.remove("d-none");
          }
          else {
            this.text.classList.add("d-none");
          }

          if (this.link && data.linkUrl) {
            this.link.href = data.linkUrl;
            this.link.innerHTML = data.linkText;
            this.link.classList.remove("d-none");
          }
          else {
            this.link?.classList.add("d-none");
          }
        }
      });
    });
  }
}

export const detailsModules = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(".js-details-module");

      if (!Boolean(elements.length)) return;

      const instances = [...elements].map(
        (element) => new DetailsModule(element)
      );

      detailsModules.current = [...detailsModules.current, ...instances];
    });
  }
}
