import app from "../a4_app";

export class PathModule {
  constructor(element) {
    this.element = element;
    this.tabs = element.querySelectorAll(".js-path-module-tab");
    this.rails = element.querySelectorAll(".js-path-module-rail");
    this.init();
  }

  init() {
    this.tabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        e.preventDefault();
        this.switchTab(tab);
      });
    });
  }

  switchTab(tab) {
    this.tabs.forEach((tab) => tab.classList.remove("active"));
    tab.classList.add("active");

    this.rails.forEach((rail) => {
      rail.classList.remove("active");
      if (rail.dataset.rail === tab.dataset.rail) {
        rail.classList.add("active");
        window.Stimulus.getControllerForElementAndIdentifier(rail, "carousel").flickity.resize();
      }
    });
  }
}

export const pathModules = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(".js-path-module");

      if (!Boolean(elements.length)) return;

      const instances = [...elements].map(
        (element) => new PathModule(element)
      );

      pathModules.current = [...pathModules.current, ...instances];
    });
  }
}

// js-path-module-tab
