import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-range-picker"
export default class extends Controller {
  static targets = [
    "input",
    "select",
    "dropdown",
    "background",
    "dateInputContainer",
    "dateInputIcon",
  ];

  connect() {
    window.drp = this;
    this.setPlaceholderFromUrl();
    this.setSelectTargetPlaceholders();
  }

  showUIElementsFor(event, inputType) {
    let it = inputType;
    if (event) {
      it = event.target.dataset.dateRangePickerQueryType;
    }
    const dropdown = this.dropdownTargets.find(el => el.dataset.dateRangePickerQueryType == it);

    if (dropdown.classList.contains("show")) {
      this.hide();
      return;
    }
    else {
      this.dateInputIconTarget.innerText = "−";
      dropdown.classList.add("show");
      this.backgroundTarget.classList.add("show");
      this.dateInputContainerTarget.classList.add("show");
    }
  }

  hover(event) {
    this.dateInputContainerTarget.classList.add("hover");
  }

  unhover(event) {
    this.dateInputContainerTarget.classList.remove("hover");
  }

  hide(event) {
    this.dropdownTargets.forEach((dropdown) => {
      dropdown.classList.remove("show")
    });

    this.backgroundTarget.classList.remove("show");
    this.dateInputContainerTarget.classList.remove("show");
    this.dateInputIconTarget.innerText = "+";
  }

  // Clear all the selectTargets and submit the form that the inputTarget is in.
  clearInput(event) {
    this.selectTargets.forEach((select) => {
      select.value = "";
    });

    // Destroy any existing fake placeholders
    const existingFakePlaceholders = document.querySelector(".fake-placeholder");
    if (existingFakePlaceholders) {
      existingFakePlaceholders.remove();
    };
    this.inputTarget.placeholder = "CHOOSE DATES";

    this.inputTarget.form.requestSubmit();
  }

  // Set the input's placeholder to the selected date range based on the URL query parameters.
  // For example:
  //  When only ?start_month is present, the placeholder will be "Since ${start_month}"
  //  When only ?end_month is present, the placeholder will be "Until ${end_month}"
  //  When both ?start_month and ?end_month are present, the placeholder will be "${start_month} - ${end_month}"
  //  When only ?start_year is present, the placeholder will be "Since ${start_year}"
  //  When only ?end_year is present, the placeholder will be "Until ${end_year}"
  //  When both ?start_year and ?end_year are present, the placeholder will be "${start_year} - ${end_year}"
  //  When ?start_year and ?start_month are present, the placeholder will be "Since ${start_month} ${start_year}"
  //  When ?end_year and ?end_month are present, the placeholder will be "Until ${end_month} ${end_year}"
  setPlaceholderFromUrl() {
    const startMonth = this.getQueryParam("start_month");
    const endMonth = this.getQueryParam("end_month");
    const startYear = this.getQueryParam("start_year");
    const endYear = this.getQueryParam("end_year");

    this.formatPlaceholderString(startMonth, startYear, endMonth, endYear);
  }

  // Do the same as above but this time instead of setting the placeholder based on URL query parameters, use the values of the <select> inputs, where the input's name attribute is the query parameter name.
  setPlaceholderFromInput() {
    const startMonth = this.selectTargets.find(select => select.name == "start_month").value;
    const endMonth = this.selectTargets.find(select => select.name == "end_month").value;
    const startYear = this.selectTargets.find(select => select.name == "start_year").value;
    const endYear = this.selectTargets.find(select => select.name == "end_year").value;

    this.formatPlaceholderString(startMonth, startYear, endMonth, endYear);
  }

  // Set the selectTargets' placeholders to their selected values based on the URL query parameters.
  setSelectTargetPlaceholders() {
    this.selectTargets.forEach((select) => {
      const value = this.getQueryParam(select.name);
      if (value) {
        select.value = value;
      }
    });
  }

  formatPlaceholderString(startMonth, startYear, endMonth, endYear) {
    // Destroy any existing fake placeholders
      const existingFakePlaceholders = document.querySelector(".fake-placeholder");
      if (existingFakePlaceholders) {
        existingFakePlaceholders.remove();
      };

    // Create a fake placeholder element that will be used to set the input's placeholder
    const fakePlaceholder = document.createElement("div");
    fakePlaceholder.classList.add("fake-placeholder");
    // Create a span element containing an x icon that will be appended to the fake placeholder element
    const xIcon = document.createElement("div");
    xIcon.classList.add(
      "public-database-form__button__input-clear",
      "public-database-form__button__input-clear--date",
      "show"
    );
    // images/svg/icon_x.svg
    xIcon.innerHTML = `
      <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.72705 2.58331L22.4998 22.7222" stroke="black" stroke-width="3"/>
        <path d="M22.5 2.58331L2.72727 22.7222" stroke="black" stroke-width="3"/>
      </svg>
    `;

    // Add data-action="click->date-range-picker#clearInput" to the x icon
    const clearInputAction = document.createAttribute("data-action");
    clearInputAction.value = "click->date-range-picker#clearInput";
    xIcon.setAttributeNode(clearInputAction);

    if (startMonth && endMonth) {
      fakePlaceholder.innerHTML = `
        <mark>
          ${this.getMonthName(startMonth).toUpperCase()} ${startYear} - ${this.getMonthName(endMonth).toUpperCase()} ${endYear}
        </mark>
      `;
    }
    else if (startMonth) {
      fakePlaceholder.innerHTML = `
        <mark>
          SINCE ${this.getMonthName(startMonth).toUpperCase()} ${startYear}
        </mark>
      `;
    }
    else if (endMonth) {
      fakePlaceholder.innerHTML = `
        <mark>
          UNTIL ${this.getMonthName(endMonth).toUpperCase()} ${endYear}
        </mark>
      `;
    }
    else if (startYear && endYear) {
      fakePlaceholder.innerHTML = `
        <mark>
          ${startYear} - ${endYear}
        </mark>
      `;
    }
    else if (startYear) {
      fakePlaceholder.innerHTML = `
        <mark>
          SINCE ${startYear}
        </mark>
      `;
    }
    else if (endYear) {
      fakePlaceholder.innerHTML = `
        <mark>
          UNTIL ${endYear}
        </mark>
      `;
    }
    else {
      return;
    }

    fakePlaceholder.appendChild(xIcon);
    // Append the fake placeholder after the input
    this.inputTarget.parentElement.insertBefore(fakePlaceholder, this.inputTarget.nextSibling);

    // Hide the input's placeholder
    this.inputTarget.placeholder = "";
  }

  // Convert a month integer to a month name
  getMonthName(month) {
    return new Date(2020, month - 1, 1).toLocaleString('default', { month: 'long' });
  }

  getQueryParam(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }
}
