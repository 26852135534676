import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["poster", "iframe"];

  hidePoster() {
    this.posterTarget.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 500,
      fill: "forwards",
    }).finished.then(() => {
      this.posterTarget.style.display = "none";
    });
  }

  showPoster() {
    this.posterTarget.style.display = "";
    this.posterTarget.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 500,
      fill: "forwards",
    });
  }

  disconnect() {
    this.showPoster();
  }

}
