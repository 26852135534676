import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "activateButton",
    "backLink",
    "header",
    "innerSearchInput",
    "menuElement",
    "searchInput",
    "showHide",
    "subscribeForm",
    "toggler",
  ];

  static values = {
    open: {
      default: false,
      type: Boolean,
    },
    listenersAdded: {
      default: false,
      type: Boolean,
    },
    searchOpen: {
      default: false,
      type: Boolean,
    },
  };

  connect() {
    this.boundHandleOutsideMenuClick = this.handleOutsideMenuClick.bind(this);
    this.boundHandleMenuKeydown = this.handleMenuKeydown.bind(this);
  }

  disconnect() {
    this.openValue = false;
  }

  openValueChanged() {
    if (this.openValue) {
      this.addOpenClasses();
      this.addMenuEventListeners();
    }
    else {
      this.removeOpenClasses();
      this.removeMenuEventListeners();
    }
  }

  searchOpenValueChanged() {
    if (this.searchOpenValue) {
      this.menuElementTarget.classList.add("menu--search-active");
    } else {
      this.menuElementTarget.classList.remove("menu--search-active");
    }
  }

  toggle() {
    this.openValue = !this.openValue;
    this.togglerTarget?.setAttribute("aria-expanded", this.openValue);
    this.menuElementTarget?.setAttribute("aria-hidden", !this.openValue);

    if (this.hasBackLinkTarget) {
      this.toggleHomeLink();
    }
  }

  toggleHomeLink() {
    if (this.openValue) {
      const homeLink = document.createElement("a");
      homeLink.classList.add("header__home-link");
      homeLink.setAttribute("href", "/");
      homeLink.innerText = "A4";
      this.backLinkTarget.classList.add("d-none");
      this.backLinkTarget.insertAdjacentElement("beforebegin", homeLink);
    }
    else {
      const homeLink = document.querySelector(".header__home-link");
      homeLink.remove();
      this.backLinkTarget.classList.remove("d-none");
    }
  }

  addOpenClasses() {
    document.body.classList.add("menu-lock");
    this.headerTarget.classList.add("header--open");
    this.menuElementTarget.classList.add("menu--open");
    this.menuElementTarget.classList.add("menu--wider");
  }

  removeOpenClasses() {
    document.body.classList.remove("menu-lock");
    this.headerTarget.classList.remove("header--open");
    this.menuElementTarget.classList.remove("menu--open");

    setTimeout(() => {
      this.menuElementTarget.classList.remove("menu--wider");
    }, 500);
  }

  addMenuEventListeners() {
    document.addEventListener("keydown", this.boundHandleMenuKeydown);
    document.querySelector(".js-main").addEventListener("click", this.boundHandleOutsideMenuClick);
    document.querySelector(".js-footer").addEventListener("click", this.boundHandleOutsideMenuClick);
    this.listenersAddedValue = true;
  }

  removeMenuEventListeners() {
    document.removeEventListener("keydown", this.boundHandleMenuKeydown);
    document.querySelector(".js-main").removeEventListener("click", this.boundHandleOutsideMenuClick);
    document.querySelector(".js-footer").removeEventListener("click", this.boundHandleOutsideMenuClick);
    this.listenersAddedValue = false;
  }

  toggleSearch() {
    this.searchOpenValue = !this.searchOpenValue;
  }

  toggleSearchBar() {
    this.showHideTargets.forEach(el => el.classList.toggle('search--active'));
    this.searchInputTarget.focus();
  }

  showHideSearchToggle() {
    this.activateButtonTarget.classList.toggle('menu--active');
  }

  closeSubscribe() {
    this.headerTarget.classList.remove('subscribe--active');
    this.subscribeFormTarget.classList.add('d-none');
  }

  toggleSearchSubscribe(e) {
    e.preventDefault();
    this.headerTarget.classList.toggle('subscribe--active');
    this.subscribeFormTarget.classList.toggle('d-none');
  }

  handleOutsideMenuClick(e) {
    // Homepage header module is a special case where we want to allow clicks,
    // and it's a part of the <main> that we normally want to trigger a menu close
    const homepageHeaderModule = document.querySelector(".js-homepage-header-module");
    if (homepageHeaderModule && homepageHeaderModule.contains(e.target)) {
      return;
    }

    e.preventDefault(); // prevent links underneath the menu from triggering a page load
    this.toggle();
  }

  handleMenuKeydown(e) {
    switch (e.key) {
      case "Escape":
        this.toggle();
        break;
    }
  }
}
