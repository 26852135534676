/*
Tooltip

To create a tooltip, add the class "js-tooltip-item" to any element
and add the text to appear in the tooltip as data-tooltip-text.

Ex:
<%= tag.div class: "item-class js-tooltip-item", data: { tooltip_text: item.text } %>
*/

import app from "../a4_app";

class Tooltip {
  constructor(element) {
    this.element = element;
    this.text = this.element.querySelector(".js-tooltip-text");
    this.items = document.querySelectorAll(".js-tooltip-item");
    this.active = false;

    this.items.forEach((item) => {
      item.addEventListener("mouseenter", (e) => {
        const text = e.target.dataset.tooltipText;

        if (text) {
          this.setPosition(e);
          this.fillText(text);
          this.open();
          this.active = true;
        }
      });

      item.addEventListener("mousemove", (e) => {
        if (this.active) {
          this.setPosition(e);
        }
      });

      item.addEventListener("mouseleave", (e) => {
        if (this.active) {
          this.close();
          this.active = false;
        }
      });
    });
  }

  setPosition(e) {
    const o = 16; // 1rem
    const x = e.clientX + o;
    const y = e.clientY + o;
    this.element.style.left = `${x}px`;
    this.element.style.top = `${y}px`;
  }

  fillText(str) {
    this.text.innerHTML = str;
  }

  fillContent(html) {
    this.element.innerHTML = html;
  }

  clear() {
    this.text.innerText = "";
  }

  open() {
    this.element.classList.remove("d-none");
  }

  close() {
    this.element.classList.add("d-none");
    this.clear();
  }
}

export const tooltip = {
  current: "",
  init: () => {
    app.addEventListener("page-load", (e) => {
      const element = e.target.querySelector(".js-tooltip");

      if (!Boolean(element) || app.isMobile()) {
        return;
      }
      else if (tooltip.current) {
        tooltip.current.close();
      }

      tooltip.current = new Tooltip(element);
    });
  }
}
