import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="load-more"
export default class extends Controller {
  static targets = ["buttonWrapper", "resultContainer"]
  static values = { url: String }
  
  nextPage = this.resultContainerTarget.dataset.loadMoreUrlValue;
  token = document.querySelector('meta[name="csrf-token"]').content;
  
  connect() {
  }

  showMore() {
    fetch(this.nextPage, {
                          mode: "same-origin", 
                          method: "POST"
                        }).then(
      response => response.text().then(data => {
        this.buttonWrapperTarget.remove();
        this.resultContainerTarget.insertAdjacentHTML("beforeend", data);
        this.nextPage = this.resultContainerTargets.slice(-1)[0].dataset.loadMoreUrlValue;
      })
    );
  }
}
