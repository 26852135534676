import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    collapsed: {
      default: false,
      type: Boolean,
    },
    showButton: {
      default: false,
      type: Boolean,
    },
  };

  static outlets = [
    "accordion",
  ];

  accordionOutletConnected(outlet) {
    this.observer.observe(outlet.element);
  }

  disconnect() {
    this.observer.disconnect();
  }

  collapsedValueChanged() {
    this.accordionOutletElements.forEach((accordion) => {
      accordion.dataset.accordionOpenValue = !this.collapsedValue;
    });
  }

  toggleCollapse() {
    this.collapsedValue = !this.collapsedValue;
  }

  get observer() {
    if (!this._observer) {
      this._observer = new IntersectionObserver((entries, observer) => {
        const anyIntersecting = entries.some(entry => entry.isIntersecting);
        if (anyIntersecting) {
          this.showButtonValue = true;
          observer.disconnect();
        }
      }, {
        threshold: [0, 1],
      });
    }

    return this._observer;
  }
}
