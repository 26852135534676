// Stimulus
import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// Stimulus Init
window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

// Component Import
import { modal } from "./components/modal";
import { tooltip } from "./components/tooltip";

// Component Init
modal.init();
tooltip.init();

// BEGIN MAGIC_MODULE_IMPORT
import { databaseEmbedModules } from "./magic_modules/database_embed_module";
import { detailsModules } from "./magic_modules/details_module";
import { homepageHeaderModules } from "./magic_modules/homepage_header_module";
import { pathModules } from "./magic_modules/path_module";
// import * as newsletterSignupModule from './magic_modules/newsletter_signup_module';
// END MAGIC_MODULE_IMPORT

// BEGIN MAGIC_MODULE_INIT
databaseEmbedModules.init();
detailsModules.init();
homepageHeaderModules.init();
pathModules.init();
// newsletterSignupModule.init();
// END MAGIC_MODULE_INIT
