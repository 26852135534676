import { Controller } from "@hotwired/stimulus";

export default class NewsletterSignup extends Controller {
  static targets = ["errorMessage", "form", "screen"];
  static values = {
    screen: String,
    errorMessage: String,
  };

  connect() {
  }

  error(message) {
    this.errorMessageValue = this.getFormattedErrorMessage(message);
    this.screenValue = "error";
  }

  reset() {
    this.screenValue = "form";
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();

    /*
      NOTE: This assumes jQuery is on the page. Unfortunately, we need to use
      jsonp to get around CORS issues. The fetch API does not support jsonp
      out of the box, so we use jQuery's ajax method instead.
    */
    $.ajax({
      type: "GET",
      url: this.data.get("ajax-action"),
      data: $(this.formTarget).serialize(),
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      error: (error) => this.error(error.message),
      success: (response) => {
        if (response.result === "error") {
          this.error(response.msg);
        } else {
          this.success();
        }
      },
    });
  }

  success() {
    this.screenValue = "success";
  }

  /*
  :::::::::::::
  :: Helpers ::
  :::::::::::::
  */
  getFormattedErrorMessage(errorMessage) {
    if (
      errorMessage.includes("NetworkError") ||
      errorMessage.includes("timeout")
    ) {
      return "Sorry, the server did not respond. Please try again later.";
    }

    if (errorMessage.includes("already subscribed")) {
      return "You're already subscribed!";
    }

    if (errorMessage.includes("invalid email address")) {
      return "Please enter a valid email address.";
    }

    if (errorMessage.includes("too many recent signup requests")) {
      return "You've made too many recent signup requests. Please try again later.";
    }

    return "Sorry, something went wrong. Please try again later.";
  }

  /*
  :::::::::::::::::::::::::::
  :: Value change handlers ::
  :::::::::::::::::::::::::::
  */
  screenValueChanged(screen) {
    this.screenTargets.forEach((el) => {
      const isCurrentScreen = el.dataset.newsletterSignupScreen === screen;
      el.style.setProperty("display", isCurrentScreen ? "flex" : "none");
      el.setAttribute("aria-hidden", !isCurrentScreen);
    });
  }

  errorMessageValueChanged(message) {
    if (!this.hasErrorMessageTarget) {
      return;
    }

    this.errorMessageTarget.textContent = message;
  }
}
