import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="typeahead"
export default class extends Controller {
  static targets = [ "input" , "dropdown", "background", "clearButton"]
  static values = {
                    url: String,
                  }

  previousInputValue = "";
  previousDisplayElement = null;

  connect() {
    const relatedToInput = this.inputTargets.find(el => el.dataset.typeaheadQueryType == "related");
    const urlParams = new URLSearchParams(window.location.search);
    if (relatedToInput && relatedToInput.dataset.titleFromQuery && relatedToInput.dataset.typeFromQuery) {
      // Set the relatedToInput's value to the related_to param from the URL query string
      const relatedToParam = urlParams.get("related_to");
      this.select({ params: {queryType: "related", info: relatedToParam, title: relatedToInput.dataset.titleFromQuery, type: relatedToInput.dataset.typeFromQuery, formParam: "related_to"} })
      // Show the clear button
      this.showClear({ target: relatedToInput });
    }

    const tagFilterInput = this.inputTargets.find(el => el.dataset.typeaheadQueryType == "tags");
    if (tagFilterInput && tagFilterInput.dataset.titleFromQuery && tagFilterInput.dataset.typeFromQuery) {
      // Set the relatedToInput's value to the related_to param from the URL query string
      const tagFilterParam = urlParams.get("tag_filter");
      this.select({ params: {queryType: "tags", info: tagFilterParam, title: tagFilterInput.dataset.titleFromQuery, type: tagFilterInput.dataset.typeFromQuery, formParam: "tag_filter"} })
      // Show the clear button
      this.showClear({ target: tagFilterInput });
    }
  }

  search(event) {
    if (event.keyCode == 8 || event.keyCode >= 48 && event.keyCode <= 90) {
      const inputType = event.target.dataset.typeaheadQueryType;
      const url = this.urlValue;
      const paramsString = `${inputType}=${event.target.value}`
      const params = new URLSearchParams(paramsString)
      event.target.dataset.inputStatus = "pending";

      if (event.target.value.length > 0) {
        this.showUIElementsFor(null, inputType);
        fetch(`${url}?${params}`, {mode: "same-origin"})
          .then(response => response.text())
          .then((data) => {
            this.dropdownTargets.find(el => el.dataset.typeaheadQueryType == inputType).innerHTML = data
          })
      };
    }
  }

  // Gets the value of the selected item and sets it to the input field
  select({ params: {queryType, info, title, type, formParam} }) {
    const input = this.inputTargets.find(el => el.dataset.typeaheadQueryType == queryType);
    const inputParent = input.parentNode;

    // remove the hidden input field if it exists
    let hiddenInput = inputParent.querySelector(`input[name="${formParam}"]`);
    if (hiddenInput) { hiddenInput.remove() };

    // create a hidden input field with the value of the selected item
    hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = formParam;
    hiddenInput.value = info;

    inputParent.insertBefore(hiddenInput, input.nextSibling);
    input.value = `${title.toUpperCase()} – ${type.toUpperCase()}`;
    this.previousInputValue = input.value;

    this.createAndInsertSelectedElement(input, title, type);

    // set the input's "input_status" data attribute to "set" so that the input field can be cleared
    input.dataset.inputStatus = "set";

    this.hide();
  }

  createAndInsertSelectedElement(input, title, type) {
    // Create a display element to enable highlight style
    // Input values cannot render HTML inside of them
    const recordInputHtmlDisplay = document.createElement("span");
    recordInputHtmlDisplay.classList.add("public-database-form__record-input-html-display");
    recordInputHtmlDisplay.innerHTML = `<mark>${title.toUpperCase()}</mark> – ${type.toUpperCase()}`;

    const inputHeight = document.querySelector(".public-database-form__record-input").offsetHeight;
    recordInputHtmlDisplay.style.minHeight = `${inputHeight}px`;
    input.insertAdjacentElement("afterend", recordInputHtmlDisplay);
    // Multiline input fields need to be resized to match the height of the display element
    // so when a user clicks to dismiss the tag, the second line is part of the event target
    if (inputHeight < recordInputHtmlDisplay.offsetHeight) {
      input.style.height = `${recordInputHtmlDisplay.offsetHeight}px`;
    }
  }

  showUIElementsFor(event, inputType) {
    let it = inputType;
    if (event) {
      it = event.target.dataset.typeaheadQueryType;
    }

    const dropdown = this.dropdownTargets.find(el => el.dataset.typeaheadQueryType == it);
    dropdown.classList.add("show")
    this.backgroundTarget.classList.add("show")
  }

  hide(event) {
    this.dropdownTargets.forEach((dropdown) => { dropdown.classList.remove("show") })
    this.backgroundTarget.classList.remove("show")
  }

  showClear(event) {
    const clearButton = this.clearButtonTargets.find(el => event.target.parentNode.contains(el));
    clearButton.classList.add("show");
  }

  // when the user clicks the close button, clear the input field and hide the dropdown
  clear(event) {
    if (this.clickedOnInput(event)) {
      if (event.target.dataset.inputStatus == "pending") {
        return;
      }
      if (event.target.dataset.inputStatus == "set") {
        const displayEl = event.target.parentNode.querySelector(".public-database-form__record-input-html-display");
        this.saveSetInput(event.target, displayEl);
        displayEl && displayEl.remove();
        event.target.value = "";
        event.target.style.height = "auto";
        this.backgroundTarget.classList.add("show");
        event.target.dataset.inputStatus = "pending";
        this.clearButtonTargets.find(el => el.parentNode.contains(event.target)).classList.remove("show");
        return;
      }
    }

    if (this.clickedOnClearButton(event)) {
      let input = this.inputTargets.find(el => el.parentNode.contains(event.target));

      if(input.dataset.inputStatus == "set") {
        const displayEl = input.parentNode.querySelector(".public-database-form__record-input-html-display");
        displayEl && displayEl.remove();
      }

      input.value = "";
      input.style.height = "auto";
      input.dataset.inputStatus = "unset";
      // set the focus to the input field
      input.focus();
      this.clearButtonTargets.find(el => el.parentNode.contains(input)).classList.remove("show");

    }

    if (this.clickedOnBackground(event)) {
      if (this.getPendingInput() && this.previousInputValue != "") {
        this.restoreSetInput(this.getPendingInput());
      } else if (this.getPendingInput()) {
        this.getPendingInput().value = "";
        this.getPendingInput().style.height = "auto";
        this.clearButtonTargets.find(el => el.parentNode.contains(this.getPendingInput())).classList.remove("show");
        this.getPendingInput().dataset.inputStatus = "unset";
      }
    }

    this.hide();
  }

  // Check if the input's "input_status" data attribute is "set". If it is set, then clear the input field.
  saveSetInput(input, displayElement) {
    // store the existing value of the input field in a variable just in case the user clicks outside of the input field
    this.previousInputValue = input.value;
    this.previousDisplayElement = displayElement;
  }

  restoreSetInput(input) {
    if (this.previousDisplayElement != null) {
      input.insertAdjacentElement("afterend", this.previousDisplayElement);
    }
    this.clearButtonTargets.find(el => el.parentNode.contains(input)).classList.add("show");
    input.value = this.previousInputValue;
    input.dataset.inputStatus = "set";
  }

  clickedOnClearButton(event) {
    return this.clearButtonTargets.find(el => el.contains(event.target));
  }

  clickedOnBackground(event) {
    return event.target == this.backgroundTarget;
  }

  clickedOnInput(event) {
    return this.inputTargets.includes(event.target);
  }

  getClickedInput(event) {
    return this.inputTargets.find(el => el.contains(event.target));
  }

  getPendingInput() {
    return this.inputTargets.find(el => el.dataset.inputStatus == "pending");
  }

  // If the user clicks outside of the input field while it is still unset, then set the input field to its previous value
  resetInput() {
    // Get the input that has the "input_status" data attribute set to "pending"
    const input = this.inputTargets.find(el => el.dataset.inputStatus == "pending");

    if (input) {
      input.value = this.previousInputValue;
      // If the input value is blank, then set the "input_status" data attribute to "unset", otherwise set it to "set"
      input.dataset.inputStatus = input.value == "" ? "unset" : "set";
    }
  }
}
