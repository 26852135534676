import { Controller } from "@hotwired/stimulus";

const TIME_PER_SLIDE = 5000;
const CROSSFADE_DURATION = 500;

export default class extends Controller {
  static targets = [
    "slideWrapper",
    "slide",
    "announcement",
    "pauseButton",
    "playButton",
    "caption",
  ];
  static values = {
    index: Number,
    maxIndex: Number,
    paused: Boolean,
  };

  connect() {
    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    );
    if (!prefersReducedMotion.matches) {
      this.pausedValue = false;
    }

    this.resizeOnHomepage();
  }

  resizeOnHomepage() {
    if (window.location.pathname === "/" || window.location.pathname === "/home") {
      this.resize();
    }
  }

  resize() {
    const mh = this.calculateSlideHeight();

    this.slideTargets.forEach((slide) => {
      const slideImage = slide.querySelector("img");
      slideImage.style.setProperty("max-height", mh);
    });
  }

  calculateSlideHeight() {
    const header = document.querySelector(".js-homepage-header-module");
    if (!header) return "65vh"; // reasonable fallback

    const headerHeight = header.offsetHeight; // integer
    const captionHeight = this.captionTargets[0].offsetHeight; // integer
    const gutterHeight = getComputedStyle(document.documentElement).getPropertyValue("--gutter"); // value and unit

    // On the homepage, the image slides take up all the available space
    // minus the header, caption, and gutters.
    //
    // There are 2 gutters in the total calculation:
    // * between the top of window and the header
    // * between the header and the slide

    return `calc(100vh - (${headerHeight}px + ${captionHeight}px + (${gutterHeight} * 2)))`;
  }

  select(e) {
    const { index } = e.params;
    this.indexValue = index;
  }

  next() {
    if (typeof this.nextIndex !== "number") {
      return;
    }

    this.indexValue = this.nextIndex;
  }

  previous() {
    if (typeof this.previousIndex !== "number") {
      return;
    }

    this.indexValue = this.previousIndex;
  }

  play() {
    this.pausedValue = false;
  }

  pause() {
    this.cancelCurrentAutoplay();
    this.pausedValue = true;
  }

  indexValueChanged(index, previousIndex) {
    // Swap out slides
    if (index !== previousIndex) {
      this.cancelCurrentAutoplay();
      const previousSlide = this.slideTargets[previousIndex];
      const currentSlide = this.slideTargets[index];
      currentSlide.style.setProperty("display", "block");
      currentSlide.style.setProperty("opacity", "0");
      currentSlide.style.setProperty("position", "absolute");
      currentSlide.style.setProperty("top", "0");
      currentSlide.style.setProperty("left", "0");
      currentSlide.style.setProperty("z-index", "2");
      previousSlide && previousSlide.style.setProperty("z-index", "1");
      const fadeOut = previousSlide
        ? previousSlide.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: CROSSFADE_DURATION,
            easing: "ease-in",
            fill: "forwards",
          }).finished
        : Promise.resolve();
      const fadeIn = currentSlide.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: CROSSFADE_DURATION,
        easing: "ease-out",
        fill: "forwards",
      }).finished;
      Promise.all([fadeOut, fadeIn]).then(() => {
        currentSlide.style.removeProperty("position");
        currentSlide.style.removeProperty("top");
        currentSlide.style.removeProperty("left");
        previousSlide.style.setProperty("display", "none");
        this.autoplay();
      });
    }

    if (this.hasAnnouncementTarget) {
      this.announcementTarget.textContent = `You are currently viewing slide ${
        index + 1
      } of ${this.slideTargets.length}`;
    }
  }

  pausedValueChanged(paused) {
    if (paused) {
      cancelAnimationFrame(this.animationFrame);
      this.pauseButtonTarget.style.setProperty("display", "none");
      this.playButtonTarget.style.setProperty("display", "inline-block");
    } else {
      this.playButtonTarget.style.setProperty("display", "none");
      this.pauseButtonTarget.style.setProperty("display", "inline-block");
      this.autoplay();
    }
  }

  cancelCurrentAutoplay() {
    cancelAnimationFrame(this.animationFrame);
  }

  autoplay() {
    if (this.pausedValue) {
      return;
    }

    const updateAnimation = function (currentTime) {
      const timeSinceLastUpdate = currentTime - this.lastUpdateTime;
      if (timeSinceLastUpdate > TIME_PER_SLIDE) {
        this.next();
        this.lastUpdateTime = currentTime;
      }

      if (!this.pausedValue) {
        this.animationFrame = requestAnimationFrame(updateAnimation);
      }
    }.bind(this);
    this.lastUpdateTime = performance.now();
    this.animationFrame = requestAnimationFrame(updateAnimation);
  }

  get nextIndex() {
    if (this.maxIndexValue === 0) {
      return false;
    } else if (this.indexValue === this.maxIndexValue) {
      return 0;
    } else {
      return this.indexValue + 1;
    }
  }

  get previousIndex() {
    if (this.maxIndexValue === 0) {
      return false;
    } else if (this.indexValue === 0) {
      return this.maxIndexValue;
    } else {
      return this.indexValue - 1;
    }
  }
}
